import {Layout, Menu, Popconfirm,Button} from 'antd'
import {
    GithubFilled,
} from '@ant-design/icons'
import './index.scss'
import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {fetchUserInfo, clearUserInfo} from '@/store/modules/user'

const {Header} = Layout

const itemList = [
    {
        label: <span style={{fontSize: 16, marginLeft: 20, marginRight: 20}}>主页</span>,
        key: '/',
    },

    {
        label: <span style={{fontSize: 16, marginLeft: 20, marginRight: 20}}>文章</span>,
        key: '/article',
    },
    {
        label: <span style={{fontSize: 16, marginLeft: 20, marginRight: 20}}>技能</span>,
        key: '/note',
    },
    {
        label: <span style={{fontSize: 16, marginLeft: 20, marginRight: 20}}>生活</span>,
        key: '/live',
    },
    {
        label: <span style={{fontSize: 16, marginLeft: 20, marginRight: 20}}>归档</span>,
        key: '/classify',
    },
    {
        label: <span style={{fontSize: 16, marginLeft: 20, marginRight: 20}}>关于</span>,
        key: '/about',
    },
    {
        label: <span style={{fontSize: 16, marginLeft: 20, marginRight: 20}}>留言</span>,
        key: '/guestbook',
    },
]

const GeekLayout = () => {
    const navigate = useNavigate()
    const [show, setShow] = useState(false)
    const onMenuClick = (route) => {
        const path = route.key
        navigate(path)
    }

    // 反向高亮
    // 1. 获取当前路由路径
    const location = useLocation()
    const selectedKey = location.pathname

    // 触发个人用户信息action
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchUserInfo()).then(r => {
                if (r.data.username) {
                    setShow(true)
                }
            }
        )
    }, [dispatch])

    // 退出登录确认回调
    const onConfirm = () => {
        console.log('确认退出')
        dispatch(clearUserInfo())
        navigate('/login')
    }

    const name = useSelector(state => state.user.userInfo.username)
    return (
        <Layout>
            <Header className="header" style={{display: 'flex', alignItems: 'center'}}>
                <div className="logo">
                    <span>
                    Mr · XuXuan
                    </span>
                </div>
                <div className="menu">
                    <Menu
                        mode="horizontal"
                        theme="dark"
                        selectedKeys={selectedKey}
                        onClick={onMenuClick}
                        items={itemList}
                        style={{height: '100%', borderRight: 0, minWidth: 1111, flex: "auto"}}>
                    </Menu>
                </div>

                <div className="user-login" style={{
                    display: show ? 'none' : 'block'
                }}>
                    <div onClick={onConfirm}>
                        <Button type="primary" size="large"> 注册/登录</Button>
                    </div>
                </div>
                <div className="user-info" style={{
                    display: show ? 'block' : 'none'
                }}>
                    <span className="user-logout">
                    <Popconfirm title="是否确认退出？" okText="退出" cancelText="取消" onConfirm={onConfirm}>
                      <GithubFilled style={{fontSize:"20px"}} /><span className='nameCss'>
                        {name}
                    </span>
                    </Popconfirm>
                    </span>
                </div>
            </Header>
            <Layout  >
                <Layout className="layout-content" style={{padding: 20}}>
                    {/* 二级路由的出口 */}
                    <Outlet/>
                </Layout>
            </Layout>
        </Layout>
    )
}
export default GeekLayout
