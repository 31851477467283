// 路由配置

import Layout from '@/pages/Layout' // src/pages/layout
import Login from '@/pages/Login'
import { createBrowserRouter } from 'react-router-dom'
import { Suspense, lazy } from 'react'
import {AuthRoute} from "@/components/AuthRoute";
// 1. lazy函数对组件进行导入
const Live = lazy(() => import('@/pages/Live'))
const Classify = lazy(() => import('src/pages/Classify'))
const About = lazy(() => import('@/pages/About'))
const Guestbook = lazy(() => import('@/pages/Guestbook'))
const Note = lazy(() => import('src/pages/Note'))
const Home = lazy(() => import('@/pages/Home'))
const Publish = lazy(() => import('src/pages/Article'))

// 配置路由实例

const router = createBrowserRouter([
  {
    path: "/",
    element:<Layout />,
    children: [
      {
        index: true,
        element: <Suspense fallback={'加载中'}><Home /></Suspense>
      },

      {
        path: 'Note',
        element: <Suspense fallback={'加载中'}><Note /></Suspense>
      },
      {
        path: 'article',
        element: <Suspense fallback={'加载中'}><Publish /></Suspense>
      },
      {
        path: 'live',
        element: <Suspense fallback={'加载中'}><Live /></Suspense>
      },
      {
        path: 'classify',
        element: <Suspense fallback={'加载中'}><Classify /></Suspense>
      },
      {
        path: 'about',
        element: <Suspense fallback={'加载中'}><About /></Suspense>
      },
      {
        path: 'guestbook',
        element: <AuthRoute> <Suspense fallback={'加载中'}><Guestbook /></Suspense> </AuthRoute>
      },

    ]
  },
  {
    path: "/login",
    element: <Login />
  }
])

export default router
